import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Banner from "$components/Banner"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import PostPreview from "$components/PostPreview"
import SEO from "$components/SEO"

import bannerArticles from "./assets/banner-articles.jpg"

const BannerContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Breadcrumbs = styled(Container)`
  font-size: 1.1rem;
  margin: 1rem auto;

  ${Link} {
    color: ${props => props.theme.tundora};
  }
`

const title = "Articles | Lotus Network Solutions"
const description =
  "Read our latest articles about current issues in information technology."

export default ({ data }) => (
  <>
    <SEO description={description} title={title} />
    <Header />
    <main>
      <Banner bannerImage={bannerArticles} height={400}>
        <BannerContainer>
          <h1>Articles</h1>
        </BannerContainer>
      </Banner>
      <Breadcrumbs>
        &#171; Back to <Link to="/insights/">Insights</Link>
      </Breadcrumbs>
      <section>
        <Container>
          <h2>All Articles</h2>
          <PostPreview nodes={data.wpcontent.posts.nodes} />
        </Container>
      </section>
    </main>
    <Footer />
  </>
)

export const query = graphql`
  query {
    wpcontent {
      posts {
        nodes {
          author {
            name
          }
          date
          excerpt
          featuredImage {
            altText
            mediaItemUrl
          }
          slug
          title
        }
      }
    }
  }
`
